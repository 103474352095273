//获取数据分析列表
const getAnalysisList = `/gateway/hc-data/datacenter/analysis/page`
//获取模型管理列表
const getModelList = `/gateway/hc-data/datacenter/model/page`
//获取结果预览
const getShowList = `/gateway/hc-data/datacenter/analysis/field/list`
//获取数据分析详情
const getDetail = `/gateway/hc-data/datacenter/analysis/detail`
//获取模型管理详情
const getModelDeatil = `/gateway/hc-data/datacenter/model/detail`
//新建离线分析
const addAnaly = `/gateway/hc-data/datacenter/analysis/submit`
//离线分析编辑
const deleteAnaly = `/gateway/hc-data/datacenter/analysis/remove`
//获取数据源列表
const getSourceList = `/gateway/hc-data/datacenter/source/page`;
//获取数据
const getPredictList = `/gateway/hc-data/datacenter/analysis/predict/list`;
export {
  getAnalysisList,
  getModelList,
  getShowList,
  getDetail,
  getModelDeatil,
  addAnaly,
  deleteAnaly,
  getSourceList,
  getPredictList,
};