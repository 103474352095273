var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sourceForm"},[_c('div',{staticClass:"bottom-wrap"},[_c('search-data',{ref:"search",attrs:{"title":'数据分析名称：',"placeholder":'请输入名称',"search-url":_vm.getAnalysisList,"params":_vm.params,"otherFildeShowTitle":_vm.showTitle,"otherFildeShowAry":_vm.showAry,"isOtherFileds":true,"isShow":false,"isShowOpear":true,"isShowIcon":false},on:{"goNextEvent":_vm.goNext,"goNextAdd":_vm.goAdd,"goViewInfo":_vm.goViewInfo,"del":_vm.deletAnaly}})],1),_c('div',[_c('el-dialog',{attrs:{"title":_vm.title,"close-on-click-modal":false,"visible":_vm.dialogFit,"width":"50%"},on:{"update:visible":function($event){_vm.dialogFit=$event},"close":_vm.getInit}},[_c('form-panel',{ref:"formPanel",staticClass:"form-panel",attrs:{"labelWidth":'120px',"form":_vm.form,"hasHeader":false,"label-position":'left'}},[_c('div',{staticClass:"title"},[_vm._v("基本信息")]),_c('el-form-item',{attrs:{"label":"名称","rules":[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ],"prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"title"},[_vm._v("选择模型")]),_c('el-form-item',{attrs:{"label":"数据类型","prop":"type","rules":[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]}},[_c('v-radio',{attrs:{"radioObj":_vm.radioObj},on:{"change":_vm.handelChange},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),(_vm.form.type == 0)?_c('el-form-item',{attrs:{"label":"模型名称","rules":[
              {
                required: false,
                message: '请选择模型名称',
                trigger: 'blur',
              },
            ],"prop":"datamodelId"}},[_c('el-select',{attrs:{"filterable":"","remote":"","clearable":true,"reserve-keyword":"","placeholder":"请输入关键词","remote-method":_vm.remoteMethod},on:{"change":_vm.getChange},model:{value:(_vm.modelName),callback:function ($$v) {_vm.modelName=$$v},expression:"modelName"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),(_vm.form.type == 1)?_c('el-form-item',{attrs:{"label":"数据源名称","rules":[
              {
                required: false,
                message: '请选择模型名称',
                trigger: 'blur',
              },
            ],"prop":"datasourceId"}},[_c('el-select',{attrs:{"filterable":"","remote":"","clearable":true,"reserve-keyword":"","placeholder":"请输入关键词","remote-method":_vm.getDataOriginList},model:{value:(_vm.form.datasourceId),callback:function ($$v) {_vm.$set(_vm.form, "datasourceId", $$v)},expression:"form.datasourceId"}},_vm._l((_vm.dataOrigin),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1):_vm._e(),_c('div',{staticClass:"title"},[_vm._v("离线分析")]),_c('el-form-item',{attrs:{"label":"查询内容","rules":[
              {
                required: true,
                message: '请输入查询sql',
                trigger: 'blur',
              },
            ],"prop":"querySql"}},[_c('v-input',{staticClass:"item-input",attrs:{"clearable":"","type":"textarea","placeholder":"请输入查询sql","width":310,"maxlength":10000},model:{value:(_vm.form.querySql),callback:function ($$v) {_vm.$set(_vm.form, "querySql", $$v)},expression:"form.querySql"}})],1),_c('el-form-item',[_c('v-button',{attrs:{"text":"结果预览"},on:{"click":function($event){return _vm.getShow()}}})],1),_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tabLoad),expression:"tabLoad"}]},[(_vm.tableList.length > 0)?_c('v-table',{ref:"vTable",attrs:{"border":"","tableData":_vm.tableList,"isOperateColumn":false,"headers":_vm.headers}}):_vm._e()],1),(_vm.show)?_c('div',[_vm._v("预览结果为空")]):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.getInit(), (_vm.dialogFit = false)}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确定")])],1)],1)],1),_c('v-dialog',{staticClass:"dialog",attrs:{"title":"查看预测数据","sureTxt":"关闭"},on:{"confirm":_vm.confirm},model:{value:(_vm.isShowDialog),callback:function ($$v) {_vm.isShowDialog=$$v},expression:"isShowDialog"}},[_c('v-table',{ref:"vTable",attrs:{"height":"auto","tableData":_vm.dTableData,"isOperateColumn":false,"headers":_vm.dHeaders}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }